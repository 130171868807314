import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import parse from "html-react-parser";
import { useEffect } from "react";

import ErrorPage from "../../../../../templates/error/Error";
import { Events, track } from "../../../../../utils/analytics";
import { getCurrentURLSearchParams } from "../../../../../utils/browser-features";

const Page = (): JSX.Element => {
  const { t } = useTranslation();
  const currentURLSearchParams = getCurrentURLSearchParams();
  const isMonthlyPayment = currentURLSearchParams?.get("is_monthly_payment");

  useEffect(() => {
    track(Events.SWITCH_TO_MONTHLY_ERROR_VIEWED_BROWSER);
  }, []);

  const subtitle = !isMonthlyPayment ? (
    <>
      <p>{t("bundle.switch_to_monthly.error.not_renewable.description")}</p>
      <p>
        {parse(
          t("bundle.switch_to_monthly.error.help", {
            email: "<a href='mailto:seguros@barkibu.com'>seguros@barkibu.com</a>",
          })
        )}
      </p>
    </>
  ) : (
    <p>{t("bundle.switch_to_monthly.error.is_monthly_payment")}</p>
  );

  return <ErrorPage metaTitle={"¡Ups!"} title={"¡Ups!"} subtitle={subtitle} />;
};

export default Page;

export const query = graphql`
  query SwitchToMonthlySuccessQuery {
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
